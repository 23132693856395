import './App.css';

function App() {

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('copied to clipboard!');
    });
  };

  var textToCopy = "kGZNz5qGsYXS7fUc4ZwqidhYqVrxtYhiLN5XyLppump"
  var raydiumLink="https://raydium.io/swap/?inputMint=sol&outputMint=kGZNz5qGsYXS7fUc4ZwqidhYqVrxtYhiLN5XyLppump"
  var dexscreenerLink="https://dexscreener.com/solana/ds1epx36g3jv7ez7ollg77czhe5vd8ya6xhdenekme4r"
  return (
    <div className="App">
      <div className="header">
        <h1 className="title">DAVE</h1>
        <div className="headerimgsContainer">
          <a href="https://x.com/davexsol" className="btn xbtn"><i class="fa fa-phone" aria-hidden="true"></i> </a>
          <img src={require("./assets/Dave.png")} alt="dave1" style={{ width: "40%" }}></img>
          <a href="https://t.me/davexsol" className="btn telegrambtn"><i class="fa fa-phone" aria-hidden="true"></i> </a>
        </div>
      </div>
      <div className="InfoFooter">
        <div className="footerdataContainer">
          <p>Our Partners</p>
          <a href={dexscreenerLink}><img src={require("./assets/dexscreener.png")} alt="dexscreener logo" className="partnerlogo"></img></a>
        </div>
        <div className="seperator" />
        <div className="footerdataContainer">
          <p>Available On</p>
          <a href={raydiumLink} className="container availableon"><i class="fa fa-phone" aria-hidden="true"></i> </a>
        </div>
        <div className="seperator" />
        <div className="footerdataContainer">
          <p>CA</p>
          <div className="container CA">
            <p className="CAText">{textToCopy}</p>
            <div className="copyButton" onClick={handleCopyClick}><p>Copy</p></div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default App;
